import React from 'react'

import Header from "../common/Header";
import Contact from "./Contact";
import Services from "./Services";
import Footer from '../common/Footer';
import ImageCarousel from '../common/ImageCarousel';

function Home() {
  return (
    <>
      <Header activeChoice='home' />
      <ImageCarousel />
      <Services />
      <Contact />
      <Footer />
    </>
  )
}

export default Home;
