import styled from 'styled-components'
import React from 'react'
import device from '../../device'

import Header from "../common/Header";
import banner from "../../assets/azbesztmentesites.jpg"
import Footer from '../common/Footer';

const BannerImage = styled.img`
  width: 100%;
  object-fit: cover;

  height: 50vw;
  @media only screen and ${device.tablet} {
    height: 400px;
    min-height: 400px;
  }
`

const Content = styled.div`
  max-width: 1220px;
  margin: 0 auto;
  text-align: justify;
  padding: 0 2rem;
`

const Paragraph = styled.p`
  font-weight: 300;
  font-family: 'Fira Sans', sans-serif;
`

const Heading = styled.h3`
  font-weight: 300;
  font-family: 'Fira Sans', sans-serif;
`

const Bold = styled.span`
  font-weight: 700;
  font-family: 'Fira Sans', sans-serif;
`

const List = styled.ul<{
  dots: boolean
}>`
  font-weight: 300;
  font-family: 'Fira Sans', sans-serif;
  list-style-type: ${p => p.dots ? 'disc' : 'none'};

  li {
    padding-left: 0.5rem;
    margin-bottom: 0.2rem;
  }
`

function Azbeszt() {
  return (
    <>
      <Header activeChoice='azbeszt' />
      <BannerImage src={banner} alt={'banner'}/>
      <Content>
        <Heading>Azbesztmentesítés: az egészsége védelemében</Heading>
        <Paragraph>
          A XX. század végére közel 3000 azbesztet tartalmazó́ termék született és az élet számos területén
          megjelent ez az anyag. A világ fejlődésével, a tömeges használattal és az azbeszt környezetbe való́
          kikerülésével azonban fokozatosan derült fény annak káros, emberi rákkeltő hatására. 
        </Paragraph>
        <Paragraph>
          E miatt világszerte programok indultak el bizonyos azbesztfajták korlátozására és mentesítésére.
        </Paragraph>
        <Paragraph>
          Az azbesztet számos iparágban, például az építőiparban alkalmaztak hazánkban.
          A XX. század elejétől állítottak elő a legnagyobb mennyiségben azbesztcement palát és csövet.
          Épületekben és műszaki berendezésekben egyaránt használták tűz-, hő- és hangszigetelésre.
          A kevésbé káros, ám a legnagyobb mennyiségben használt (a világ azbeszt termelésének 90%-át kitevő)
          fehér azbesztet (krizotil) 2005. január 1-ig lehetett használni. Főbb felhasználási területei tűz,
          hő- és zajvédelem, illetve azbesztcement palatetők épületeken, ezeken túl szigetelés,
          csomagolás, tömítés az iparban, valamint a fékbetétek és kuplungtárcsák járművekben.
        </Paragraph>
        <Paragraph>
          <Bold>Szórt azbeszt:</Bold> az emberi egészségre legkárosabb azbeszt termék,
          a kb. 90%-ban azbesztet tartalmazó, kevés cementtel gyengén kötött szórt azbeszt.
          Ez a termék hamar elöregszik és a lemálló azbesztszálak a legkisebb behatásra is (pl.: szélfuvallat)
          a levegőbe kerülnek.
        </Paragraph>
        <Paragraph>
          <Bold>Azbesztcement:</Bold> az összes felhasznált azbeszt jelentősebb része az emberi egészségre
          lényegesen kevésbé kockázatos azbesztcement formában kerül forgalomba.
          A cementben megkötött, a végtermékben csak kb. 10-15% azbesztrostot tartalmazó termékből a veszélyt
          jelentő szálak csak az anyag szétmorzsolásakor kerülnek ki a levegőbe.
        </Paragraph>
        <Paragraph>
          <Bold>Porlódó azbesztet tartalmazó anyagok:</Bold> igen elterjedt a tűzvédelemben, például laboratóriumokban.
          Léteznek emellett kevés azbesztet tartalmazó különböző szigetelő lapok is.
        </Paragraph>
        <Paragraph>
          <Bold>Az azbeszt egészségi hatásai:</Bold><br/>
          Az azbeszt mikroszkopikus méretű, elemi szálakból áll. A levegőbe kerülő̂ azbeszt szálak belélegezve
          a légutakba kerülnek, az apróbbak egész mélyre jutnak a tüdőbe, ahol fennakadhatnak.
          A bekerült lebonthatatlan azbeszt betokozódik és kóros elváltozások kialakulását idézheti elő,
          több éves lappangási idővel. A legismertebb, azbeszt okozta megbetegedés az <Bold>azbesztózis</Bold>,
          mely légzési nehézségekhez vezet. Az azbeszt belégzése, a fent említettek mellett mellhártyagyulladást is okozhat.
        </Paragraph>
        <Paragraph>
          Tapasztalt csapatunk professzionális, komplett szolgáltatást nyújt,
          modern technológiát alkalmazunk a veszélyes anyagok eltávolítására, elszállítására.
        </Paragraph>
        <Paragraph>
          Ne késlekedjen! Az azbeszttartalmú anyagok veszélyei mindig jelen vannak.
          Forduljon hozzánk még ma, és hagyja, hogy gondoskodjunk otthona vagy munkahelye szakszerű azbesztmentesítéséről.
        </Paragraph>
        <Paragraph>
          <Bold>Szolgáltatásaink:</Bold>
        </Paragraph>
        <List dots={true}>
          <li>Épületek felmérése</li>
          <li>Anyagok mintavételes azbeszttartalom vizsgálata</li>
          <li>Szórt azbeszt tartalmú szigetelések eltávolítása</li>
          <li>Azbesztcement tartalmú anyagok /pala, eternitcső/ elbontása</li>
        </List>
      <Paragraph>
        Kérjen ajánlatot: info@enviroconstruct.hu
      </Paragraph>
      </Content>
      <Footer />
    </>
  )
}

export default Azbeszt;
