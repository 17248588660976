import styled from "styled-components";
import device from "../../device";

import { AppColors } from "../../colors";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${AppColors.SECONDARY};

  justify-content: space-around;
  padding-bottom: 2rem;
  @media only screen and ${device.tablet} {
    flex-direction: row;
  }
`;

const Section = styled.div`
  text-align: center;
`;
const SectionHeader = styled.h2`
  margin: 2rem 0 1rem 0;
  font-weight: 300;
  font-family: "Fira Sans", sans-serif;
`;

const SectionContentHeader = styled.p`
  font-weight: 600;
  font-family: "Fira Sans", sans-serif;
  font-size: 1.3rem;
  margin: 0.2rem 0;

  a {
    color: #000;
  }
`;

function Footer() {
  return (
    <Root>
      <Section>
        <SectionHeader>CÉGADATOK</SectionHeader>
        <SectionContentHeader>
          Cégnév: EnviroConstruct Kft.
        </SectionContentHeader>
        <SectionContentHeader>
          Cégjegyzékszám: 20-09-077608
        </SectionContentHeader>
        <SectionContentHeader>Adószám: 23586279220</SectionContentHeader>
        <SectionContentHeader>
          Székhely: 8772 Zalaszentbalázs, Kossuth utca 216.
        </SectionContentHeader>

        <SectionHeader>ELÉRHETŐSÉG</SectionHeader>
        <SectionContentHeader>
          <a href="">info@enviroconstruct.hu</a>
        </SectionContentHeader>
      </Section>
    </Root>
  );
}

export default Footer;

