
export enum AppColors {
  PRIMARY_LIGHT = '#CBB9EF3D',
  PRIMARY = '#444444',
  PRIMARY_DARK = '#DC706F',
  BLACK = '#27251F',
  SECONDARY = '#EFEEE1',
  SECONDARY_LIGHT = '#FFE6D9',
  GREEN_MAIN = '#8cc045',
  SUCCESS = '#87C5C5',
  ERROR = '#DC706F',
  WHITE = '#FFFFFF',
  LIGHT_GREY = '#F6F6F7',
  GREYISH = '#DFDBDB',
  GREY = '#9D9797',
  DARK_GREY = '#4D4646',
  YELLOW = '#F6C427',
}


