import styled from 'styled-components'
import React from 'react'
import device from '../../device'

import Header from "../common/Header";
import banner from "../../assets/vizkarmentesites.jpg"
import Footer from '../common/Footer';

const BannerImage = styled.img`
  width: 100%;
  object-fit: cover;

  height: 50vw;
  @media only screen and ${device.tablet} {
    height: 400px;
    min-height: 400px;
  }
`

const Content = styled.div`
  max-width: 1220px;
  margin: 0 auto;
  text-align: justify;
  padding: 0 2rem;
`

const Heading = styled.h3`
  font-weight: 300;
  font-family: 'Fira Sans', sans-serif;
`

const Paragraph = styled.p`
  font-weight: 300;
  font-family: 'Fira Sans', sans-serif;
`

const List = styled.ul<{
  dots: boolean
}>`
  font-weight: 300;
  font-family: 'Fira Sans', sans-serif;
  list-style-type: ${p => p.dots ? 'disc' : 'none'};

  li {
    margin-bottom: 0.2rem;
  }
`

function Vizkar() {
  return (
    <>
      <Header activeChoice='vizkar' />
      <BannerImage src={banner} alt={'banner'}/>
      <Content>
        <Heading>Vízkármentesítés - Az Ön Nyugalmának Visszaállítója!</Heading>
        <Paragraph>
          Bármikor és bármilyen mértékben, egy vízkár bekövetkezése mindig váratlanul ér bennünket,
          és gyors intézkedéseket igényel. Az elázott ingatlanok és értéktárgyak gyakran álmatlan éjszakákat
          és nagy költségeket eredményezhetnek. De most itt van a jó hír: nem hagyjuk magára ebben a helyzetben!
        </Paragraph>
        <Paragraph>
          Miért érdemes minket választani? 
        </Paragraph>
        <List dots={true}>
          <li>Tapasztalt Szakemberek - Vízkármentesítés terén képzett és tapasztalt szakértőink azonnal cselekednek,
            hogy megakadályozzák a kár további terjedését.</li>
          <li>Villámgyors Reagálás - Az első órák kritikusak, és mi azonnal reagálunk,
            hogy megakadályozzuk a károk súlyosbodását.</li>
          <li>Modern Technológiák - Rendelkezünk a vízkármentesítő eszközökkel és módszerekkel,
            amelyek garantálják a hatékony helyreállítást.</li>
          <li>Teljes Körű Tisztítás – Az épületeket rendbe hozzuk, a bútorokat megtisztítjuk,
            a vízkár érintetteként károsodott területeket helyreállítjuk.</li>
          <li>Garancia - Bízunk a munkánk minőségében, és garanciát vállalunk a helyreállított területekre.</li>
        </List>
        <Paragraph>
          Legyen otthonában vagy irodájában mi itt vagyunk, hogy segítsünk visszaállítani az élet
          minőségét és a békeérzetet. Ne hagyja, hogy a vízkár további rombolást okozzon!
        </Paragraph>
        <Paragraph>
          Vállalati Vízkármentesítés: Vállalati környezetben is gyorsan és hatékonyan állítjuk helyre a normális működés.
        </Paragraph>
        <Paragraph>
          Kérjen ajánlatot: info@enviroconstruct.hu
        </Paragraph>
      </Content>
      <Footer />
    </>
  )
}

export default Vizkar;
