import React from 'react'
import styled from 'styled-components'

interface HorizontalRuleProps {
  thickness: string;
  color: string;
}

const HRule = styled.div<HorizontalRuleProps>`
  width: 100%;
  border-bottom: ${p => p.thickness} solid ${p => p.color};
`

function HorizontalRule(props: HorizontalRuleProps){
  return (
    <HRule {...props}/>
  )
}

export default HorizontalRule;