import styled from "styled-components";
import React from "react";
import device from "../../device";

import Header from "../common/Header";
import banner from "../../assets/tuzvedelem.jpg";
import Footer from "../common/Footer";

const BannerImage = styled.img`
  width: 100%;
  object-fit: cover;

  height: 50vw;
  @media only screen and ${device.tablet} {
    height: 400px;
    min-height: 400px;
  }
`;

const Content = styled.div`
  max-width: 1220px;
  margin: 0 auto;
  text-align: justify;
  padding: 0 2rem;
`;

const Heading = styled.h3`
  font-weight: 300;
  font-family: "Fira Sans", sans-serif;
`;

const Paragraph = styled.p`
  font-weight: 300;
  font-family: "Fira Sans", sans-serif;
`;

const List = styled.ul`
  font-weight: 300;
  font-family: "Fira Sans", sans-serif;
`;

function Tuzvedelem() {
  return (
    <>
      <Header activeChoice="tuzved" />
      <BannerImage src={banner} alt={"banner"} />
      <Content>
        <Heading>
          Passzív Tűzvédelem az Épületek a benne lévő személyek Biztonságáért!
        </Heading>
        <Paragraph>
          Az épületek biztonságának szempontjából a passzív tűzvédelem egy olyan
          alapvető tényező, amely megóvhatja Önt, és épületeket a teljes
          tragédiától. Azonban a passzív tűzvédelem értéke gyakran alábecsülve
          marad. Most itt az ideje, hogy felfedezze ezt az életmentő és
          értékmentő technológiát és beruházásokat tegyen a biztonságába!
        </Paragraph>
        <Paragraph>Miért kulcsfontosságú a passzív tűzvédelem?</Paragraph>
        <List>
          <li>
            Előrelátó Megközelítés - A passzív tűzvédelem kialakítása és
            karbantartása előre tervezett biztonságot nyújt az esetleges tűzvész
            esetén.
          </li>
          <li>
            Az Épületi Szerkezetek Védelme - A megfelelő tűzgátló anyagok és
            konstrukciók segítségével megelőzhető a tűz terjedése és a súlyosabb
            károk.
          </li>
          <li>
            Idő a Mentésre - A passzív tűzvédelem az időt adja az épületben
            tartózkodóknak és a tűzoltóknak a menekülésre és az oltásra
          </li>
        </List>
        <Paragraph>
          Az Ön Épülete is Védelemre Szorul! Legyen szó otthonáról, irodáról
          vagy ipari létesítményről, a passzív tűzvédelem mindenhol fontos. A
          megfelelő tűzgátló szerkezetek és anyagok segítségével
          minimalizálhatja a kockázatot és védelmet nyújthat az Ön és mások
          számára.
        </Paragraph>
        <Paragraph>
          Vállalati Megoldások: Vállalata számára is biztonságosabb
          munkakörnyezetet teremthetünk, minimalizálva a termelési leállásokat
          és a vagyonkárokat.
        </Paragraph>
        <Paragraph>
          Ne hagyja, hogy a tűz tragédiát okozzon! Válassza a passzív tűzvédelem
          szakértőit, és fektessen be az Ön és mások biztonságába!
        </Paragraph>
        <Paragraph>Kérjen ajánlatot: info@enviroconstruct.hu</Paragraph>
      </Content>
      <Footer />
    </>
  );
}

export default Tuzvedelem;
