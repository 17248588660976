import React from 'react'
import styled from 'styled-components'

interface VPadProps {
  height: string;
}

const VPadElem = styled.div<VPadProps>`
  width: 100%;  
  height: ${p => p.height};
`

function VPad(props: VPadProps){
  return (
    <VPadElem {...props}/>
  )
}

export default VPad;