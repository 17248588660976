import React from "react";
import styled from "styled-components";
import device from "../../device";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import styles from "./Contact.module.css";

const Root = styled.div`
  background-color: #8cc045;
  padding: 1rem;
`;

const InnerRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 0rem;
  border: 1px solid white;
`;

const Title = styled.h1`
  color: #ffffff;
  margin: 0;
  font-weight: 300;
  font-family: "Fira Sans", sans-serif;

  font-size: 1.2rem;
  @media only screen and ${device.tablet} {
    font-size: 2.125rem;
  }
`;

const ContactButton = styled.div`
  background-color: #f6c427;
  color: #393939;
  font-size: 1.8rem;
  font-family: "PT Sans Narrow", sans-serif;
  &:hover {
    background-color: #ffd957;
  }

  width: 360px;
  height: 140px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
`;

function Contact() {
  return (
    <Root>
      <InnerRoot>
        <Link to="/contact" className={styles.ContactButtonLink}>
          <ContactButton>
            <FontAwesomeIcon icon={faEnvelope} size={"xl"} />
            AJÁNLAT KÉRÉS
          </ContactButton>
        </Link>
      </InnerRoot>
    </Root>
  );
}

export default Contact;
