import React from "react";
import styled from "styled-components";
import device from "../../device";

import GreenDot from "../../assets/green_dot.png";
import HorizontalRule from "../../components/HorizontalRule";
import VPad from "../../components/VPad";
import { Link } from "react-router-dom";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  padding: 1rem 0rem;
`;

const Title = styled.h1`
  font-family: "Fira Sans", sans-serif;
  font-weight: 300;
  margin: 2rem 0 0 0;
`;

const ServicesRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  margin-top: 3rem;

  @media only screen and ${device.tablet} {
    flex-direction: row;
  }

  a,
  a:hover,
  a:active,
  a:visited,
  a:focus,
  a:focus-within {
    text-decoration: none !important;
    color: #000;

    h2 {
      text-decoration: none !important;
      color: #000;
    }
  }
`;

const ServicesEntry = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 0 1rem;
  width: 20rem;

  background-color: rgba(140, 192, 69, 0.8);
  padding: 20px;

  img {
    width: 50px;
    height: 50px;
  }
`;

const ServicesEntryTitle = styled.h2`
  margin: 0;
  font-weight: 300;
  font-family: "Fira Sans", sans-serif;
  font-size: 1.8rem;
`;

const Green = styled.div`
  min-height: 5rem;
  padding: 3rem;
`;

const GreenDotImage = styled.img`
  width: 150px;
  padding: 2rem 0rem;
`;

function Services() {
  return (
    <Root>
      <HorizontalRule color="#d2d2d2" thickness="1px" />
      <Title>Szolgáltatásaink</Title>
      <ServicesRow>
        <Link to="/azbeszt">
          <ServicesEntry>
            <img src="/skull.svg" />
            <ServicesEntryTitle>Azbesztmentesítés</ServicesEntryTitle>
          </ServicesEntry>
        </Link>
        <Link to="/tuzkar">
          <ServicesEntry>
            <img src="/fire.svg" />
            <ServicesEntryTitle>Tűzkármentesítés</ServicesEntryTitle>
          </ServicesEntry>
        </Link>
        <Link to="/vizkar">
          <ServicesEntry>
            <img src="/droplet.svg" />
            <ServicesEntryTitle>Vízkármentesítés</ServicesEntryTitle>
          </ServicesEntry>
        </Link>
      </ServicesRow>
      <ServicesRow>
        <Link to="/tuzved">
          <ServicesEntry>
            <img src="/fire-extinguisher.svg" />
            <ServicesEntryTitle>Tűzvédelem</ServicesEntryTitle>
          </ServicesEntry>
        </Link>
        <Link to="/iparitiszt">
          <ServicesEntry>
            <img src="/broom.svg" />
            <ServicesEntryTitle>Ipari tisztítás</ServicesEntryTitle>
          </ServicesEntry>
        </Link>
      </ServicesRow>
      <VPad height="2rem" />
      <HorizontalRule color="#d2d2d2" thickness="1px" />
    </Root>
  );
}

export default Services;
