import styled from "styled-components";
import React, { useState } from "react";
import device from "../../device";

import { AppColors } from "../../colors";
import Header from "../common/Header";
import Footer from "../common/Footer";

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 2rem;
  margin-top: 5rem;
`;

const ContactRoot = styled.div`
  margin: 1rem 0;
  padding: 2rem;
  width: 40%;
  border: 1px solid ${AppColors.SECONDARY};

  padding-right: 5rem;
`;

const ContactForm = styled.form`
  width: 100%;
`;

const FormInput = styled.input`
  background-color: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 60%;
  padding: 1rem;
  margin: 0.5rem 0;
  font-size: 1.1rem;
  font-family: "Fira Sans", sans-serif;
  width: 100%;
`;

const FormTextArea = styled.textarea`
  width: 80%;
  background-color: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 1rem;
  margin: 0.5rem 0;
  font-family: "Fira Sans", sans-serif;
  height: 10rem;
  resize: vertical;
  width: 100%;
  font-size: 1.1rem;
`;
const SendButton = styled.button<{
  hidden: boolean;
}>`
  display: ${(p) => (p.hidden ? "none" : "block")};
  color: ${AppColors.PRIMARY};
  background-color: ${AppColors.YELLOW};
  border: none;
  padding: 1rem 2rem;
  margin: 0.5rem 0;
  font-size: 1rem;
  font-weight: 400;
  font-family: "PT Sans Narrow", sans-serif;
  cursor: pointer;
`;

const ThankYouText = styled.h3<{
  hidden: boolean;
}>`
  display: ${(p) => (p.hidden ? "none" : "block")};
  font-weight: 300;
  font-family: "Fira Sans", sans-serif;
`;

function ContactPage() {
  const [messageSent, setMessageSent] = useState<boolean>(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setMessageSent(true);
    const target = event.target as typeof event.target & {
      name: { value: string };
      email: { value: string };
      company: { value: string };
      subject: { value: string };
      message: { value: string };
    };

    const data = {
      senderName: target.name.value,
      senderEmail: target.email.value,
      company: target.company.value,
      subject: target.subject.value,
      message: target.message.value,
    };
    //call to the Netlify Function you created
    await fetch("./.netlify/functions/triggerContactEmail", {
      method: "POST",
      body: JSON.stringify({
        fromEmail: "info@enviroconstruct.hu",
        senderName: data.senderName,
        senderEmail: data.senderEmail,
        company: data.company,
        subject: data.subject,
        message: data.message,
      }),
    });
  };

  return (
    <>
      <Header activeChoice="contact" />
      <Content>
        <ContactRoot>
          <ContactForm onSubmit={handleSubmit}>
            <FormInput
              placeholder="Ön neve"
              type="text"
              size={40}
              name="name"
              required={true}
            />
            <FormInput
              placeholder="Cégnév (opcionális)"
              type="text"
              size={40}
              name="company"
              required={false}
            />
            <FormInput
              placeholder="Ön email címe"
              type="text"
              size={40}
              name="email"
              required={true}
            />
            <FormInput
              placeholder="Tárgy"
              type="text"
              size={40}
              name="subject"
              required={true}
            />
            <FormTextArea
              placeholder="Üzenet"
              cols={40}
              rows={10}
              name="message"
              required={true}
            />
            <SendButton hidden={messageSent}>Küldés</SendButton>
          </ContactForm>
          <ThankYouText hidden={!messageSent}>✓ Köszönjük!</ThankYouText>
        </ContactRoot>
      </Content>
      <Footer />
    </>
  );
}

export default ContactPage;
