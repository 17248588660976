import styled from 'styled-components'
import React from 'react'
import device from '../../device'

import Header from "../common/Header";
import banner from "../../assets/tuzkarmentesites.jpg"
import Footer from '../common/Footer';

const BannerImage = styled.img`
  width: 100%;
  object-fit: cover;

  height: 50vw;
  @media only screen and ${device.tablet} {
    height: 400px;
    min-height: 400px;
  }
`

const Content = styled.div`
  max-width: 1220px;
  margin: 0 auto;
  text-align: justify;
  padding: 0 2rem;
`

const Heading = styled.h3`
  font-weight: 300;
  font-family: 'Fira Sans', sans-serif;
`

const Paragraph = styled.p`
  font-weight: 300;
  font-family: 'Fira Sans', sans-serif;
`

const Bold = styled.p`
  font-weight: 700;
  font-family: 'Fira Sans', sans-serif;
`

const List = styled.ul<{
  dots: boolean
}>`
  font-weight: 300;
  font-family: 'Fira Sans', sans-serif;
  list-style-type: ${p => p.dots ? 'disc' : 'none'};

  li {
    padding-left: 0.5rem;
    margin-bottom: 0.2rem;
  }
`

function Tuzkar() {
  return (
    <>
      <Header activeChoice='tuzkar' />
      <BannerImage src={banner} alt={'banner'}/>
      <Content>
        <Heading>Tűzkármentesítés: Értékei és biztonsága védelmében!</Heading>
        <Paragraph>A tűz mindent elragad, de mi itt vagyunk, hogy megvédjük, amit a legfontosabbnak tart.
            A tűzkármentesítés nem csak egy szolgáltatás, hanem egy kötelesség az értékei védelmében.</Paragraph>
        <Bold>Miért válassza tűzkármentesítésünket?</Bold>
        <List dots={true}>
          <li>Professzionális szolgáltatás: tapasztalt csapatunk
            a legkorszerűbb technikákkal dolgozik, hogy minimalizáljuk a tűz okozta károkat.</li>
          <li>Otthona védelme: Az otthon a biztonság és a kényelem szigete.
            Tűzkármentesítéssel megőrizheti otthona integritását és megmentheti pótolhatatlan emlékeit.</li>
          <li>Üzleti biztonság: Az üzlete kulcsfontosságú. A tűzkármentesítés
            segít megőrizni az üzleti folytonosságot a kieső termelésiidő minimalizálásával,
            így megőrizheti ügyfelei bizalmát.</li>
          <li>Teljeskörű szolgáltatás: Igény esetén a bontástól az
            újjáépítésig minden szükséges lépést megteszünk, hogy értékeit visszaállítsuk a tűz előtti állapotba.</li>
          <li>Környezetvédelem: A tűz során megsemmisült anyagok gyakran veszélyesek.
            Gondoskodunk azok biztonságos eltávolításáról és kezeléséről a környezet védelme érdekében.
              Az alkalmazott vegyszereink biológiailag lebomlóak.</li>
          <li>Gyors reakció: A tűz utáni első 72 órában lehet a leghatékonyabban cselekedni.
            Ne habozzon, hívjon minket azonnal.</li>
        </List>
        <Paragraph>Garancia és biztonság: Cégünk vállalja, hogy az általa megtisztított felületeken és berendezéseken
           -ezen tűzkárból fakadóan- további károsodás nem következik be.</Paragraph>
        <Paragraph>Az értékei védelme és biztonsága az elsődleges számunkra.
           Ne hagyja, hogy a tűz pusztítson, válassza tűzkármentesítésünket és hagyja,
            hogy mi segítsünk az értékei megmentésében.</Paragraph>
        <Paragraph>Szolgáltatásaink:</Paragraph>
        <List dots={true}>
          <li>veszélyessé vált épületrészek elbontása</li>
          <li>a következménykárok megelőzésére tett azonnali intézkedések</li>
          <li>épületszerkezetek vegyszeres korommentesítése</li>
          <li>gépek, berendezések vegyszeres tisztítása, korommentesítése</li>
          <li>oltóvíz okozta károk elhárítása</li>
          <li>tűzeset utáni szagsemlegesítés</li>
          <li>épületszerkezetek helyreállítása</li>
        </List>
        <Paragraph>
          Kérjen ajánlatot: info@enviroconstruct.hu
        </Paragraph>
      </Content>
      <Footer />
    </>
  )
}

export default Tuzkar;
