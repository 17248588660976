import styled from 'styled-components'
import React from 'react'
import device from '../../device'

import Header from "../common/Header";
import banner from "../../assets/iparitisztitas.jpg"
import Footer from '../common/Footer';

const BannerImage = styled.img`
  width: 100%;
  object-fit: cover;

  height: 50vw;
  @media only screen and ${device.tablet} {
    height: 400px;
    min-height: 400px;
  }
`

const Content = styled.div`
  max-width: 1220px;
  margin: 0 auto;
  text-align: justify;
  padding: 0 2rem;
`

const Heading = styled.h3`
  font-weight: 300;
  font-family: 'Fira Sans', sans-serif;
`

const Paragraph = styled.p`
  font-weight: 300;
  font-family: 'Fira Sans', sans-serif;
`

const List = styled.ul<{
  dots: boolean
}>`
  font-weight: 300;
  font-family: 'Fira Sans', sans-serif;
  list-style-type: ${p => p.dots ? 'disc' : 'none'};

  li {
    margin-bottom: 0.2rem;
  }
`

function Iparitiszt() {
  return (
    <>
      <Header activeChoice='iparitiszt' />
      <BannerImage src={banner} alt={'banner'}/>
      <Content>
        <Heading>Professzionális Ipari Tisztítási Szolgáltatások</Heading>
        <Paragraph>
          Szüksége van hatékony és megbízható ipari tisztításra?
          Ne keressen tovább! Cégünk a legmagasabb színvonalú ipari tisztítási szolgáltatásokat kínálja Önnek.
        </Paragraph>
        <Paragraph>
          Miért minket válasszon?
        </Paragraph>
        <List dots={true}>
          <li>Tapasztalt szakemberek: Képzett és tapasztalt csapatunk az Ön szolgálatában áll,
            akik ismerik az ipari tisztítás minden fortélyát.</li>
          <li>Speciális felszerelések: Rendelkezünk a legmodernebb tisztítóeszközökkel és technológiákkal,
            hogy a legjobb eredményt érjük el.</li>
          <li>Környezetbarát megoldások: Felelősségteljesen kezeljük a tisztítási folyamatokat a környezet megóvása érdekében.</li>
        </List>
        <Paragraph>
          Mely területekre terjed ki szolgáltatásunk?
        </Paragraph>
        <List dots={true}>
          <li>Gyárak és üzemek</li>
          <li>Raktárak</li>
          <li>Ipari létesítmények</li>
        </List>
        <Paragraph>
          Ha kiváló minőségű ipari tisztítást keres, keressen minket!
        </Paragraph>
        <Paragraph>
          Kérjen ajánlatot: info@enviroconstruct.hu
        </Paragraph>
      </Content>
      <Footer />
    </>
  )
}

export default Iparitiszt;
