import './assets/fonts.css'
import './style.css'

import React from 'react'

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/home/Home';
import Tuzkar from './pages/tuzkar/Tuzkar';
import Vizkar from './pages/vizkar/Vizkar';
import Azbeszt from './pages/azbeszt/Azbeszt';
import Tuzvedelem from './pages/tuzvedelem/Tuzvedelem';
import Iparitiszt from './pages/iparitiszt/Iparitiszt';
import ContactPage from './pages/contact/ContactPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/tuzkar" element={<Tuzkar />} />
        <Route path="/vizkar" element={<Vizkar />} />
        <Route path="/azbeszt" element={<Azbeszt />} />
        <Route path="/tuzved" element={<Tuzvedelem />} />
        <Route path="/iparitiszt" element={<Iparitiszt />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
