import styled from "styled-components";
import React, { useState } from "react";
import device from "../../device";

import Logo from "../../assets/EnviroConstruct_logo.png";
import { AppColors } from "../../colors";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const Root = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${AppColors.SECONDARY};
  min-height: 4rem;

  justify-content: space-between;
  @media only screen and ${device.tablet} {
    justify-content: center;
  }
`;

const NavElemRoot = styled.div<{
  $elemActive?: boolean;
}>`
  padding: 2.5rem 1rem;
  a {
    color: ${AppColors.PRIMARY};
    font-family: "PT Sans Narrow", sans-serif;
    letter-spacing: 0.75px;
    padding: 0.35rem 0;
    text-decoration: none;
    border-bottom: ${(p) =>
      p.$elemActive ? "2px solid " + AppColors.GREEN_MAIN : "none"};
  }
  &:hover {
    a {
      border-bottom: 2px solid ${AppColors.GREEN_MAIN};
    }
  }

  display: none;
  @media only screen and ${device.tablet} {
    min-width: 130px;
    text-align: center;
    display: block;
  }
`;

const ImageSpacer = styled.div`
  position: relative;
  width: 150px;
  margin: 0 2rem;
  @media only screen and ${device.tablet} {
    margin: 0;
  }
  z-index: 2;
`;

const EnviroLogoContainer = styled.div`
  position: absolute;
  background-color: ${AppColors.SECONDARY};
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;

  top: -30px;
  @media only screen and ${device.tablet} {
    top: -40px;
  }
`;

const EnviroLogo = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;

  @media only screen and ${device.tablet} {
    width: 150px;
    height: 150px;
  }
`;

const HamburgerRoot = styled.div`
  min-width: 3rem;
  min-height: 3rem;
  margin: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  @media only screen and ${device.tablet} {
    display: none;
  }
`;

const HaburgerMenuRoot = styled.div<{
  $show: boolean;
}>`
  position: absolute;
  top: 3rem;
  left: -6rem;
  padding: 1rem;
  background-color: ${AppColors.SECONDARY};
  display: ${(p) => (p.$show ? "flex" : "none")};
  flex-direction: column;
  gap: 1rem;
  z-index: 2;
`;
const HamburgerMenuElement = styled.div`
  font-family: "PT Sans Narrow", sans-serif;
  letter-spacing: 1px;

  a {
    color: inherit;
    text-decoration: none;
  }
`;

interface HeaderProps {
  activeChoice:
    | "home"
    | "azbeszt"
    | "tuzkar"
    | "vizkar"
    | "iparitiszt"
    | "tuzved"
    | "contact";
}

function Header(props: HeaderProps) {
  const { activeChoice } = props;
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  function toggleHamburgerMenu() {
    setHamburgerOpen(!hamburgerOpen);
  }

  return (
    <Root>
      <NavElemRoot $elemActive={activeChoice === "azbeszt"}>
        <Link to="/azbeszt">Azbesztmentesítés</Link>
      </NavElemRoot>
      <NavElemRoot $elemActive={activeChoice === "tuzkar"}>
        <Link to="/tuzkar">Tűzkármentesítés</Link>
      </NavElemRoot>
      <NavElemRoot $elemActive={activeChoice === "vizkar"}>
        <Link to="/vizkar">Vízkármentesítés</Link>
      </NavElemRoot>
      <ImageSpacer>
        <Link to="/">
          <EnviroLogoContainer>
            <EnviroLogo src={Logo} alt="logo" />
          </EnviroLogoContainer>
        </Link>
      </ImageSpacer>
      <NavElemRoot $elemActive={activeChoice === "tuzved"}>
        <Link to="/tuzved">Tűzvédelem</Link>
      </NavElemRoot>
      <NavElemRoot $elemActive={activeChoice === "iparitiszt"}>
        <Link to="/iparitiszt">Ipari tisztítás</Link>
      </NavElemRoot>
      <NavElemRoot $elemActive={activeChoice === "contact"}>
        <Link to="/contact">Ajánlat kérés</Link>
      </NavElemRoot>
      <HamburgerRoot onClick={toggleHamburgerMenu}>
        <FontAwesomeIcon icon={faBars} size="lg" />
        <HaburgerMenuRoot $show={hamburgerOpen}>
          <HamburgerMenuElement>
            <Link to="/azbeszt">Azbesztmentesítés</Link>
          </HamburgerMenuElement>
          <HamburgerMenuElement>
            <Link to="/tuzkar">Tűzkármentesítés</Link>
          </HamburgerMenuElement>
          <HamburgerMenuElement>
            <Link to="/vizkar">Vízkármentesítés</Link>
          </HamburgerMenuElement>
          <HamburgerMenuElement>
            <Link to="/tuzved">Tűzvédelem</Link>
          </HamburgerMenuElement>
          <HamburgerMenuElement>
            <Link to="/iparitiszt">Ipari tisztítás</Link>
          </HamburgerMenuElement>
          <HamburgerMenuElement>
            <Link to="/contact">Ajánlat kérés</Link>
          </HamburgerMenuElement>
        </HaburgerMenuRoot>
      </HamburgerRoot>
    </Root>
  );
}

export default Header;

