
import styled from 'styled-components'
import device from '../../device'

import azbeszt from '../../assets/azbesztmentesites.jpg'
import tuzkar from '../../assets/tuzkarmentesites.jpg'
import vizkar from '../../assets/vizkarmentesites.jpg'
import iparitiszt from '../../assets/iparitisztitas.jpg'
import tuzved from '../../assets/tuzvedelem.jpg'

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom'


const BannerImage = styled.img`
  width: 100%;
  object-fit: cover;

  height: 50vw;
  @media only screen and ${device.tablet} {
    height: calc(100vh - 8rem);
    min-height: 600px;
  }
`

const ImageEntryOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  a {
    text-decoration: none;
    color: black;
  }
`

const BannerText = styled.h1`
  position: absolute;
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 300;
  background-color: rgba(124,174,87, 0.7);

  font-size: 2rem;
  bottom: 40px;
  left: 50px;
  padding: 1rem;

  @media only screen and ${device.tablet} {
    font-size: 4rem;
    bottom: 70px;
    left: 150px;
    padding: 2rem;
  }
  cursor: pointer;
`

function ImageCarousel() {
  return (
    <Carousel autoPlay={true} infiniteLoop={true} emulateTouch={false}
      showArrows={false} showIndicators={false} showStatus={false} showThumbs={false}
      stopOnHover={false} interval={8000}>
      <div>
        <BannerImage src={azbeszt} alt=""/>
        <ImageEntryOverlay>
          <Link to="/azbeszt">
            <BannerText>Azbesztmentesítés</BannerText>
          </Link>
        </ImageEntryOverlay>
      </div>
      <div>
        <BannerImage src={tuzkar} alt=""/>
        <ImageEntryOverlay>
          <Link to="/tuzkar">
            <BannerText>Tűzkármentesítés</BannerText>
          </Link>
        </ImageEntryOverlay>
      </div>
      <div>
        <BannerImage src={vizkar} alt=""/>
        <ImageEntryOverlay>
          <Link to="/vizkar">
            <BannerText>Vízkármentesítés</BannerText>
          </Link>
        </ImageEntryOverlay>
      </div>
      <div>
        <BannerImage src={iparitiszt} alt=""/>
        <ImageEntryOverlay>
          <Link to="/iparitiszt">
            <BannerText>Ipari tisztítás</BannerText>
          </Link>
        </ImageEntryOverlay>
      </div>
      <div>
        <BannerImage src={tuzved} alt=""/>
        <ImageEntryOverlay>
          <Link to="/tuzved">
            <BannerText>Tűzvédelem</BannerText>
          </Link>
        </ImageEntryOverlay>
      </div>
    </Carousel>
  )
}

export default ImageCarousel;